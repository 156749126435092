<template>
    <div style="width: 100%;" v-loading="loading">
        <div style="width: 96%; margin-left:2%; height:320px; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">渠道数据(单位:比索 MXN)</p>
            <table style="width: 99.5%; margin-top: -10px; line-height: 38px;">
                <el-row :gutter="20" style="margin-left: 5px;">
                    <el-col :span="6">
                        <el-card shadow="always">
                            <div>汇总数据</div>
                            <el-row>
                                <el-col :span="12">
                                    <div>
                                        账户余额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value1}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        可结算金额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value2}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        不可用余额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value3}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        提现中金额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value4}}</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-col>

                    <el-col :span="6" v-if="total.iden != null" >
                        <el-card shadow="always" style="background-color: #ebf4ff;">
                            <div>STP</div>
                            <el-row>
                                <el-col :span="12">
                                    <div>
                                        账户余额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value5}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        可结算金额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value6}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        不可用余额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value7}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        提现中金额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value8}}</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-col>

                    <el-col :span="6" v-if="total.iden1 != null">
                        <el-card shadow="always" style="background-color: #fef7ea;">
                            <div>OXXO</div>
                            <el-row>
                                <el-col :span="12">
                                    <div>
                                        账户余额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value9}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        可结算金额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value10}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        不可用余额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value11}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        提现中金额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value12}}</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-col>

                    <el-col :span="6" v-if="total.iden2 != null">
                        <el-card shadow="always" style="background-color: #value13fef7ea;">
                            <div>cardPay</div>
                            <el-row>
                                <el-col :span="12">
                                    <div>
                                        账户余额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value13}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        可结算金额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value14}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        不可用余额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value15}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        提现中金额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value16}}</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-col>

                    <el-col :span="6" v-if="total.iden3 != null">
                        <el-card shadow="always" style="background-color: #value13fef7ea;">
                            <div>paynet</div>
                            <el-row>
                                <el-col :span="12">
                                    <div>
                                        账户余额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value17}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        可结算金额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value18}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        不可用余额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value19}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        提现中金额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value20}}</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-col>

                    <el-col :span="6" v-if="total.iden4 != null">
                        <el-card shadow="always" style="background-color: #value13fef7ea;">
                            <div>toppay</div>
                            <el-row>
                                <el-col :span="12">
                                    <div>
                                        账户余额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value21}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        可结算金额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value22}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        不可用余额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value23}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        提现中金额
                                        <div style="font-size: 20px;font-weight: bold;color: red;">{{total.value24}}</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-col>
                </el-row>
            </table>
        </div>
        <div style="width: 96%; margin-left:2%; height:170px; border: 1px solid #ddd; margin-top: 20px;">
            <div>
                <table style="width: 100%; margin-top: -10px; line-height: 38px;">
                    <div class="top" style="margin-left: 5px;">
                        <el-button-group>
                            <span>
                                <el-date-picker
                                    v-model="value1"
                                    size="small"
                                    value-format="yyyy-MM-dd"
                                    @change="checkdata"
                                    type="daterange"
                                    :picker-options="pickerOptions"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                            </span>
                        </el-button-group>
                    </div>
                    <div class="toptj">·
                        <div class="tjitem" >
                            <span>总代收金额</span>
                            <div>
                                <i>{{summary.value1}}</i>
                            </div>
                        </div>
                        <div class="tjitem">
                            <span>代收订单笔数</span>
                            <div>
                                <i>{{summary.value2}}</i>
                            </div>
                        </div>
                        <div class="tjitem">
                            <span>总代付金额</span>
                            <div>
                                <i>{{summary.value3}}</i>
                            </div>
                        </div>
                        <div class="tjitem">
                            <span>代付订单笔数</span>
                            <div>
                                <i>{{summary.value4}}</i>
                            </div>
                        </div>
                    </div>
                </table>
            </div>
        </div>

        <div style="width: 96%; margin-left:2%; height:360px; border: 1px solid #ddd; margin-top: 20px;">
            <div class="cont">
                <div class="contbox">
                    <div class="contboxleft">
                        <div class="title"><em></em><b>订单笔数</b></div>
                        <div class="tjcont">
                            <div ref="chart" style="width:100%;height:310px"></div>
                        </div>
                    </div>
                    <div class="contboxright">
                        <div class="title"><em></em><b>增长率</b></div>
                        <div class="tjcont">
                            <div ref="chart2" style="width:100%;height:310px"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div style="width: 96%; margin-left:2%; height:360px; border: 1px solid #ddd; margin-top: 20px;">
            <div class="cont">
                <div class="contbox">
                    <div class="contboxleft">
                        <div class="title"><em></em><b>代收金额统计</b></div>
                        <div class="tjcont">
                            <div ref="chart3" style="width:100%;height:310px"></div>
                        </div>
                    </div>
                    <div class="contboxright">
                        <div class="title"><em></em><b>代付金额统计</b></div>
                        <div class="tjcont">
                            <div ref="chart4" style="width:100%;height:310px"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="width: 96%; margin-left:2%; height:360px; border: 1px solid #ddd; margin-top: 20px;">
            <div class="cont">
                <div class="contbox">
                    <div class="contboxleft">
                        <div class="title"><em></em><b>代收渠道笔数</b></div>
                        <div class="tjcont">
                            <div ref="chart5" style="width:100%;height:310px"></div>
                        </div>
                    </div>
                    <div class="contboxright">
                        <div class="title"><em></em><b>代付渠道笔数</b></div>
                        <div class="tjcont">
                            <div ref="chart6" style="width:100%;height:310px"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
import apiurl from "@/api/public"
import _api from "@/api/index"
export default {
    data(){
        return{
            loading:false,
            flag:1,
            total:{
                value1: 0,
                value2: 0,
                value3: 0,
                value4: 0,
                value5: 0,
                value6: 0,
                value7: 0,
                value8: 0,
                value9: 0,
                value10: 0,
                value11: 0,
                value12: 0,
                value13: 0,
                value14: 0,
                value15: 0,
                value16: 0,

                value17: 0,
                value18: 0,
                value19: 0,
                value20: 0,
                value21: 0,
                value22: 0,
                value23: 0,
                value24: 0,
                iden:null,
                iden1:null,
                iden2:null,
                iden3:null,
                iden4:null,
            },
            summary:{
                value1: 0,
                value2: 0,
                value3: 0,
                value4: 0,
            },
            value1:null,
            pickerOptions: {
                onPick : ({maxDate, minDate}) => {
                    this.selectDate = minDate.getTime()
                    if (maxDate) {
                        this.selectDate = ''
                    }
                },
                disabledDate : (time) => {
                    if (this.selectDate !== '') {
                        const one = 6 * 24 * 3600 * 1000
                        const minTime = this.selectDate - one
                        const maxTime = this.selectDate + one
                        return time.getTime() < minTime || time.getTime() > maxTime
                    }
                }
            },
            option:{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                grid: {
                    top:'15%',
                    left: '2.5%',
                    right: '5%',
                    bottom: '0%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: ['12-16', '12-17', '12-18', '12-19', '12-20', '12-21', '12-22'],
                        axisLabel: {      
                            interval:0,//代表显示所有x轴标签显示
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '订单笔数/笔'
                    }
                ],
                series: [
                    {
                        type: 'line',
                        smooth: true,
                        label: {
                            normal: {
                                show: true,
                                position: 'top'
                            }
                        },
                        areaStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                                    offset: 0, color: 'rgba(66, 14, 26,0.6)' // 0% 处的颜色
                                        }, {
                                            offset: 0.4, color: 'rgba(66, 14, 26,0.2)' // 100% 处的颜色
                                        }, {
                                            offset: 1, color: 'rgba(9, 54, 102,0)' // 100% 处的颜色
                                    }]
                                ), //背景渐变色 
                            }
                        },
                        itemStyle: {
                            normal: {
                            color: '#471923',
                            label : {
                                show: false
                            },
                            lineStyle:{
                                color: '#471923'
                            }
                            }
                        },
                        data: [820, 932, 901, 934, 1290, 1330, 1320]
                    },
                ]
            },
            option2:{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    data: ['代收笔数增长率', '代收金额增长率','代付笔数增长率', '代付金额增长率']
                },
                grid: {
                    top:'15%',
                    left: '5%',
                    right: '8%',
                    bottom: '0%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: ['12-16', '12-17', '12-18', '12-19', '12-20', '12-21', '12-22'],
                        axisLabel: {
                            interval:0,//代表显示所有x轴标签显示
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '增长率/%'
                    }
                ],
                series: [
                    {
                        name: '代收笔数增长率',
                        type: 'line',
                        smooth: true,
                        label: {
                            normal: {
                                show: true,
                                position: 'top',
                            },
                            
                        },
                        itemStyle: {
                            normal: {
                            color: '#7ed3f4',
                            label : {
                                show: false
                            },
                            lineStyle:{
                                color: '#7ed3f4'
                            }
                            }
                        },
                        data: [820, 932, 901, 934, 1290, 1330, 1320]
                    },
                    {
                        name: '代收金额增长率',
                        type: 'line',
                        smooth: true,
                        label: {
                            normal: {
                                show: true,
                                position: 'top',
                            }
                        },
                        itemStyle: {
                            normal: {
                            color: '#ff7e7e',
                            label : {
                                show: false
                            },
                            lineStyle:{
                                color: '#ff7e7e'
                            }
                            }
                        },
                        data: [820, 932, 901, 934, 1290, 1330, 1320]
                    },
                    {
                        name: '代付笔数增长率',
                        type: 'line',
                        smooth: true,
                        label: {
                            normal: {
                                show: true,
                                position: 'top',
                            }
                        },
                        itemStyle: {
                            normal: {
                            color: '#fac858',
                            label : {
                                show: false
                            },
                            lineStyle:{
                                color: '#fac858'
                            }
                            }
                        },
                        data: [820, 932, 901, 934, 1290, 1330, 1320]
                    },{
                        name: '代付金额增长率',
                        type: 'line',
                        smooth: true,
                        itemStyle: {
                            normal: {
                            color: '#5c7bd9',
                            label : {
                                show: false,
                                position: 'top',
                            },
                            lineStyle:{
                                color: '#5c7bd9'
                            }
                            }
                        },
                        data: [820, 932, 901, 934, 1290, 1330, 5]
                    }
                ]
            },
            option3:{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                grid: {
                    top:'15%',
                    left: '5%',
                    right: '2%',
                    bottom: '0%',
                    containLabel: true
                },
                legend: {
                    data: ['STP', 'OXXO']
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['12-16', '12-17', '12-18', '12-19', '12-20', '12-21', '12-22'],
                        axisLabel: {      
                            interval:0,//代表显示所有x轴标签显示
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '金额/比索MXN'
                    }
                ],
                series: [
                    {
                        name: 'STP',
                        type: 'bar',
                        showBackground: true,
                        barCategoryGap:'2px',
                        barWidth:30,
                        itemStyle: {
                            normal: {
                            color: '#559cdf',
                            label : {
                                show: false
                            },
                            lineStyle:{
                                color: '#559cdf'
                            }
                            }
                        },
                        data: [2, 3, 4,5,6,7,8]
                    },

                    {
                        name: 'OXXO',
                        type: 'bar',
                        showBackground: true,
                        barCategoryGap:'2px',
                        barWidth:30,
                        itemStyle: {
                            normal: {
                            color: '#2db74d',
                            label : {
                                show: false
                            },
                            lineStyle:{
                                color: '#2db74d'
                            }
                            }
                        },
                        data: [11,12,13,14,15,16,17],
                    },
                ]
            },
            option4:{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                grid: {
                    top:'15%',
                    left: '5%',
                    right: '8%',
                    bottom: '0%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['12-16', '12-17', '12-18'],
                        axisLabel: {      
                            interval:0,//代表显示所有x轴标签显示
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '金额/比索MXN'
                    }
                ],
                series: [
                    {
                        type: 'bar',
                        barCategoryGap:'10',
                        barWidth:50,
                        itemStyle: {
                            normal: {
                                color: '#471923',
                                label : {
                                    show: false
                                },
                                lineStyle:{
                                    color: '#471923'
                                }
                            }
                        },
                        data: [2, 3, 7]
                    },
                ]
            },

            option5:{
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                    data: [
                    'STP',
                    'OXXO',
                    ]
                },
                series: [
                    {
                        name: 'AmPay',
                        type: 'pie',
                        selectedMode: 'single',
                        radius: [0, '30%'],
                        label: {
                            position: 'inner',
                            fontSize: 6
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 55555, name: 'Total', selected: false },
                        ]
                    },
                    {
                        name: 'AmPay',
                        type: 'pie',
                        radius: ['45%', '60%'],
                        labelLine: {
                            length: 30
                        },
                        label: {
                            formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
                            backgroundColor: '#F6F8FC',
                            borderColor: '#8C8D8E',
                            borderWidth: 2,
                            borderRadius: 6,
                            rich: {
                            a: {
                                color: '#6E7079',
                                lineHeight: 22,
                                align: 'center'
                            },
                            hr: {
                                borderColor: '#8C8D8E',
                                width: '100%',
                                borderWidth: 1,
                                height: 0
                            },
                            b: {
                                color: '#4C5058',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 33
                            },
                            per: {
                                color: '#fff',
                                backgroundColor: '#4C5058',
                                padding: [8, 10],
                                borderRadius: 10
                            }
                            }
                        },
                        data: [
                            { value: 310, name: 'STP' },
                            { value: 102, name: 'OXXO' }
                        ]
                    }
                ]
            },

            option6:{
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                series: [
                    {
                        name: 'AmPay',
                        type: 'pie',
                        selectedMode: 'single',
                        radius: [0, '60%'],
                        label: {
                            position: 'inner',
                            fontSize: 14
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 1548, name: 'Total', selected: false },
                        ]
                    },
                ]
            },
        }
    },
    created(){
        this.value1 = this.getNowTime()
        this.getTotal()
        this.getSummary()
        this.getCharts()
    },
    methods:{
        getNowTime() {
            const start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 7)
                .toISOString()
                .replace('T', ' ')
                .split('.')[0] //默认开始时间7天前
            const end = new Date(new Date().getTime() - 3600 * 1000 * 24)
                .toISOString()
                .replace('T', ' ')
                .split('.')[0]//默认结束时间1天前
            return [start, end]
        },
        checkdata(){
            this.getCharts()
            this.getSummary()
        },
        getTotal(){
            _api.get(apiurl.summary,{
                userId:this.$route.query.userId
            }).then(res => {
                this.total.value1=res.data.value1
                this.total.value2=res.data.value2
                this.total.value3=res.data.value3
                this.total.value4=res.data.value4
                this.total.value5=res.data.value5
                this.total.value6=res.data.value6
                this.total.value7=res.data.value7
                this.total.value8=res.data.value8
                this.total.value9=res.data.value9
                this.total.value10=res.data.value10
                this.total.value11=res.data.value11
                this.total.value12=res.data.value12
                this.total.value13=res.data.value13
                this.total.value14=res.data.value14
                this.total.value15=res.data.value15
                this.total.value16=res.data.value16

                this.total.value17=res.data.value17
                this.total.value18=res.data.value18
                this.total.value19=res.data.value19
                this.total.value20=res.data.value20
                this.total.value21=res.data.value21
                this.total.value22=res.data.value22
                this.total.value23=res.data.value23
                this.total.value24=res.data.value24
                this.total.iden=res.data.iden
                this.total.iden1=res.data.iden1
                this.total.iden2=res.data.iden2
                this.total.iden3=res.data.iden3
                this.total.iden4=res.data.iden4
            })
        },
        getSummary(){
            _api.get(apiurl.sevenSummary,{
                time: this.value1,
                userId:this.$route.query.userId
            }).then(res => {
                this.summary.value1 = res.data.value1
                this.summary.value2 = res.data.value2
                this.summary.value3 = res.data.value3
                this.summary.value4 = res.data.value4
            })
        },
        getCharts(){
           _api.get(apiurl.sevenDays,{
                time: this.value1,
                userId:this.$route.query.userId
           }).then(res => {
               if(res.code == 200){
                    this.option.xAxis[0].data = res.data.dateList
                    this.option2.xAxis[0].data = res.data.dateList
                    this.option3.xAxis[0].data = res.data.dateList
                    this.option4.xAxis[0].data = res.data.dateList

                    this.option.series[0].data = res.data.amountList

                    this.option2.series[0].data = res.data.v1
                    this.option2.series[1].data = res.data.v2
                    this.option2.series[2].data = res.data.v3
                    this.option2.series[3].data = res.data.v4

                    this.option3.series[0].data = res.data.v5
                    this.option3.series[1].data = res.data.v6

                    this.option4.series[0].data = res.data.v7

                    this.option5.series[0].data[0].value = res.data.v8
                    this.option5.series[1].data[0].value = res.data.v9[0]
                    this.option5.series[1].data[1].value = res.data.v9[1]

                    this.option6.series[0].data[0].value = res.data.v10
                    
                    this.getEchartData()
                    this.getEchartData2()
                    this.getEchartData3()
                    this.getEchartData4()
                    this.getEchartData5()
                    this.getEchartData6()
               }
           })
        },
        getEchartData() {
            const chart = this.$refs.chart
            if (chart) {
                const myChart = this.$echarts.init(chart)
                myChart.setOption(this.option)
                window.addEventListener("resize", function() {
                    myChart.resize();
                })
            }
            this.$on('hook:destroyed',()=>{
                window.removeEventListener("resize", function() {
                    myChart.resize();
                });
            })
        },
        getEchartData2() {
            const chart = this.$refs.chart2
            if (chart) {
                const myChart = this.$echarts.init(chart)
                myChart.setOption(this.option2)
                window.addEventListener("resize", function() {
                    myChart.resize()
                })
            }
            this.$on('hook:destroyed',()=>{
                window.removeEventListener("resize", function() {
                    myChart.resize();
                });
            })
        },
        getEchartData3() {
            const chart = this.$refs.chart3
            if (chart) {
                const myChart = this.$echarts.init(chart)
                myChart.setOption(this.option3)
                window.addEventListener("resize", function() {
                    myChart.resize()
                })
            }
            this.$on('hook:destroyed',()=>{
                window.removeEventListener("resize", function() {
                    myChart.resize();
                });
            })
        },
        getEchartData4() {
            const chart = this.$refs.chart4
            if (chart) {
                const myChart = this.$echarts.init(chart)
                myChart.setOption(this.option4)
                window.addEventListener("resize", function() {
                    myChart.resize()
                })
            }
            this.$on('hook:destroyed',()=>{
                window.removeEventListener("resize", function() {
                    myChart.resize();
                });
            })
        },
        getEchartData5() {
            const chart = this.$refs.chart5
            if (chart) {
                const myChart = this.$echarts.init(chart)
                myChart.setOption(this.option5)
                window.addEventListener("resize", function() {
                    myChart.resize()
                })
            }
            this.$on('hook:destroyed',()=>{
                window.removeEventListener("resize", function() {
                    myChart.resize();
                });
            })
        },
        getEchartData6() {
            const chart = this.$refs.chart6
            if (chart) {
                const myChart = this.$echarts.init(chart)
                myChart.setOption(this.option6)
                window.addEventListener("resize", function() {
                    myChart.resize()
                })
            }
            this.$on('hook:destroyed',()=>{
                window.removeEventListener("resize", function() {
                    myChart.resize();
                });
            })
        },
    }
}
</script>
<style scoped>
    .ta {
        margin-top: 10px; 
        margin-bottom: 10px;                   
        line-height: 38px;
        width: 800px;
        text-align: left;
        width: 750px;
    }
    .div1 {
        width: 150px;
        height: 50px;
        line-height: 50px;
        background-color: aqua;
        border: 1px solid  black;
        text-align: center;
        font-size: 20px;
        border-radius: 5px;
        margin-left: 100px;
    }

.toptj{ display: flex;margin-top: -8px;}
.tjitem{ flex: 1; background: #ffffff; cursor: pointer; border: #ebedf0 solid 1px; height: 100px; margin-right:10px;padding: 8px 12px;}
.tjitem:last-child{margin-right:0; }
.tjitem span{ display: block; color: #999999; padding-top: 4px;}
.tjitem div{ height: 25px; line-height: 25px; margin-top: 10px;}
.tjitem div i{ font-size: 24px; font-weight: bold; color: #333;}
.tjitem div em{ float: right; color: #999999;}
.contbox{ overflow: hidden; display: flex;}
.contboxleft{ flex: 0 0 50%;}
.contboxright{flex: 0 0 50%;}
.tjcont{ padding-left: 15px;}
.title em{ vertical-align: middle; display: inline-block; width: 3px; height:15px; background: #FF7300;}
.title b{ vertical-align: middle; margin-left:8px; font-size: 16px;}
.title a{ float: right; margin-right:20px; font-size: 12px; color: #56a3f3;}
.phcont{ padding:8px 0; padding-right: 25px;}
.phspan{ display: inline-block; color: #ffffff; font-size: 12px; padding:0 7px;  line-height: 20px; height: 20px; text-align: center; border-radius: 10px;}
.spanother{ background: #b9b9b9;}
.span1{ background:#CD012C;}
.span2{ background:#BE4C05;}
.span3{ background:#093666;}
.buttonon{background:rgb(230, 235, 240)!important;color:rgb(9, 54, 102)!important;border:rgb(181, 195, 209) solid 1px!important;}

</style>